<template>

<com-content class="home">

	<com-content class="home-hero"> 

		<h1>Conventions made easy</h1>
		<p>Setup and run boardgame events effortlessly with an all-in-one hub.</p>
		<router-link :to="{name: 'Signup'}" class="head-menu-item is-button">Get started</router-link>

		<div class="home-hero-scroll" v-if="window.is.mobile"><i class="fa-solid fa-chevrons-down"></i></div>

	</com-content>

	<com-content class="home-preview" v-if="!window.is.mobile"> 

		<com-preview />

	</com-content>

	<com-content class="home-services" :white="true">

		<h2>Four services, one solution.</h2>

		<com-content :inner="true" class="home-services-wrapper">

			<com-service v-for="(service, index) in services" :key="index" :item="service" />

		</com-content>

	</com-content>

	<com-content class="home-testimonials" :white="true"> 

		<com-testimonial />

	</com-content>

</com-content>
	
</template>

<script>

export default {

	components: {

		'com-content': () => import('./common/Content'),
		'com-preview': () => import('./home/Preview'),
		'com-service': () => import('./home/Service'),
		'com-testimonial': () => import('./home/Testimonial')

	},

	data: function() {

		return {
			services: [
				{
					image: 'a',
					title: 'Registration',
					text: 'The Cardboard Events registration system offers everything your boardgame convention needs a good registration system to do. It\'s simple and it seamlessly integrates with all of our other Cardboard Events systems, thus saving your convention both time and money.'
				},
				{
					image: 'b',
					title: 'Schedule',
					text: 'The Cardboard Events scheduling system is in a class by itself, offering the most robust board game convention scheduling system on the market with both an intuitive and visually appealing interface that simply is hard to beat.'
				},
				{
					image: 'c',
					title: 'Checkout',
					text: 'Every boardgame convention needs a good library checkout system and Cardboard Events has you covered. A simple QR-coded process on both the games and the badges will have your next convention\'s checkout system running like a well-oiled machine.'
				},
				{
					image: 'd',
					title: 'Library',
					text: 'The Cardboard Events library system seamlessly integrates with other CBE systems to allow your attendees to both find the games they want to play and print the QR codes needed to check the games out from your convention library.'
				}
			]
		}

	}

}

</script>

<style scoped>

.home-hero {
	height: calc(100vh - var(--vh-offset, 0px) - 300px);
	padding: 180px 0px 100px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.is-mobile .home-hero {
	height: calc(100vh - var(--vh-offset, 0px) - 0px);
	padding: 160px 0px 100px 0px;
}

.home-hero h1 {
	font-weight: 500;
	font-size: 72px;
	margin-bottom: 10px;
	color: #fff;
}

.is-mobile .home-hero h1 {
	font-size: 24px;
	text-align: center;
}

.home-hero p {
	font-size: 28px;
    line-height: 34px;
    font-weight: 300;
    text-align: center;
	color: #fff;
}

.is-mobile .home-hero p {
	font-size: 16px;
    font-weight: 400;
	padding: 0px 10px;
	line-height: 20px;
}

.home-hero a {
	font-size: 18px;
	color: #fff;
	font-weight: bold;
	padding: 15px 40px;
	margin-top: 40px;
	display: inline-block;
	text-transform: uppercase;
	background-color: #ffbe7a;
	letter-spacing: 1px;
	border-radius: 4px;
	font-weight: 600;
    color: #333;
	transition: transform 100ms linear;
}

.is-desktop .home-hero a:hover {
	transform: scale(1.05);
}

.is-mobile .home-hero a {
	font-size: 14px;
	padding: 15px 20px;
}

.home-hero-scroll {
	color: #ffbe7a;
	font-size: 32px;
	position: absolute;
	bottom: 20px;
}

.home-preview {
	z-index: 2;
}

.home-services {
	padding-top: 380px;
	margin-top: -280px;
	z-index: 1;
}

.is-mobile .home-services {
	padding-top: 40px;
	margin-top: 0px;
}

.home-services h2 {
	font-size: 28px;
    line-height: 34px;
    font-weight: 500;
    text-align: center;
    color: #444;
	margin-bottom: 40px;
}

.is-mobile .home-services h2 {
	font-size: 20px;
	line-height: 20px;
}

.home-services-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 40px;
	align-items: start;
	padding-bottom: 100px;
}

.is-mobile .home-services-wrapper {
	grid-template-columns: 1fr;
	padding: 0px 10px 40px 10px;
	grid-gap: 20px;
}

.home:before {
	display: block;
	content: '';
    background-image: url(https://api.bigbox.dev/files/uploads/62e2ed3b99bbe.jpg);
    background-position: 50% 100%;
	background-size: cover;
	background-repeat: no-repeat;
    background-size: cover;
    background-color: #2f3a4a;
    filter: sepia(100%) hue-rotate(190deg) saturate(400%) brightness(30%);
	left: 0px;
	top: 0px;
	position: absolute;
	z-index: 0;
	width: 100vw;
	height: calc(100vh - var(--vh-offset, 0px));
}

.is-desktop .home-testimonials:before {
	display: block;
	background-color: #1e3054;
	content: '';
	position: absolute;
	left: 0px;
	top: 50%;
	bottom: 0px;
	width: 100%;
}

</style>